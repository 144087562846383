div.container {
  padding: 5px 0 5px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
  align-items: center;
}

div.container > .wrapper {
  border: 0px solid black;
  padding: 10px;
  width: 99%;
  /* max-width: 955px; */
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
    gap: 20px;
  }
}

div.panel {
  padding: 10px;
  height: 1024px;
  position: relative;
}

.pic {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
