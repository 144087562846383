.footer {
  position: relative;
  bottom: 0;
  background: #0e2e5c;
  color: #fff;
  width: 100%;
  height: 30rem;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
}

.navbar__link {
  margin: 5px 0 0 10px;
  color: #fff;
  text-decoration: none;
}

div.container {
  border: 0px solid white;
  padding: 5px 0 5px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

div.container > .wrapper {
  border: 0px solid white;
  max-width: 1300px;
  width: 99%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.container > .wrapper > .title {
  padding: 20px;
  display: flex;
}
