div.container {
  padding: 5px 0 5px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
  align-items: center;
}

div.container > .wrapper {
  border: 0px solid black;
  padding: 10px;
  width: 99%;
  /* max-width: 955px; */
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
    gap: 20px;
  }
}

.content {
  background-color: #efefef;
  padding: 10px;
  width: 78%;
}
