div.container {
  padding: 5px 0 5px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
  align-items: center;
}

div.container > .wrapper {
  border: 0px solid black;
  padding: 10px;
  width: 99%;
  /* max-width: 955px; */
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
    gap: 20px;
  }
}

.card {
  display: flex;
  width: 100%;
  height: 300px;
  margin-top: 55px;
  border-radius: 70px;
  background-color: #efefef;
}

.card > .head {
  width: 22%;
  position: relative;
}

.card > .head > .wrapper {
  border-radius: 50%;
  width: 65%;
  height: 50%;
  background-color: #0e2e5c;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.card > .body {
  width: 100%;
  display: flex;
}

.card > .body > .wrapper {
  border: 0px solid black;
  width: 70%;
  position: relative;
  > div {
    border: 0px solid black;
    height: 150px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .title {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-size: 22px;
    line-height: 2em;
    font-weight: 800;
  }

  .content {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-size: 17px;
    padding-right: 10px;
  }

  .tools {
    float: right;
  }
}

.card > .body > .image {
  border: 0px solid black;
  width: 30%;
  position: relative;

  .pic {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 10%;
    padding: 10px;
  }
}

.heading {
  color: #0e2e5c;
  width: 200px;
  text-align: center;
  margin: auto;
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.carousel {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 500px;
  gap: 10px;

  > div {
    flex: 0 0 120px;
    border-radius: 0.5rem;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    box-shadow: 1px 5px 15px #0e2e5c;
    position: relative;
    overflow: hidden;

    &:nth-of-type(1) {
      background: url("../../../images/pics/IMG-3046.jpg") no-repeat 50% / cover;
    }
    &:nth-of-type(2) {
      background: url("../../../images/pics/IMG-3047.jpg") no-repeat 50% / cover;
    }
    &:nth-of-type(3) {
      background: url("../../../images/pics/IMG-3766.jpg") no-repeat 50% / cover;
    }
    &:nth-of-type(4) {
      background: url("../../../images/pics/IMG-3770.jpg") no-repeat 50% / cover;
    }
    &:nth-of-type(5) {
      background: url("../../../images/pics/IMG-3943.jpg") no-repeat 50% / cover;
    }
    &:nth-of-type(6) {
      background: url("../../../images/pics/IMG-4015.jpg") no-repeat 50% / cover;
    }
    &:nth-of-type(7) {
      background: url("../../../images/pics/IMG-4016.jpg") no-repeat 50% / cover;
    }

    .content {
      font-size: 1.5rem;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 15px;
      opacity: 0;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
      background: rgb(2, 2, 46);
      background: linear-gradient(
        0deg,
        rgba(2, 2, 46, 0.6755077030812324) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      transform: translatey(100%);
      transition: opacity 0.5s ease-in-out, transform 0.5s 0.2s;
      visibility: hidden;

      span {
        display: block;
        margin-top: 5px;
        font-size: 1.2rem;
      }
    }

    &:hover {
      flex: 0 0 250px;
      box-shadow: 1px 3px 15px #0e2e5c;
      transform: translatey(-30px);
    }

    &:hover .content {
      /* opacity: 1; */
      transform: translatey(0%);
      visibility: visible;
    }
  }
}
