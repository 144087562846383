div.container {
  padding: 5px 0 5px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
  align-items: center;
}

div.container > .wrapper {
  border: 0px solid black;
  padding: 10px;
  width: 99%;
  /* max-width: 955px; */
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
    gap: 20px;
  }
}

.heading {
  color: #0e2e5c;
  width: 600px;
  text-align: center;
  margin: auto;
}

.title {
  color: #0e2e5c;
}

.tools {
  float: right;
}

.service__link {
  margin: 5px 0 0 0px;
  color: #0e2e5c !important;
  text-decoration: none;
  font-weight: 600;
}

.service__link::before {
  background-color: #0e2e5c !important;
  font-weight: 600;
}
